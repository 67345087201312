
















































.banner-container{
display: flex;
align-items: center;
justify-content: space-between;
}
.close-icon-banner{
margin-left: 177px;
}
