







































































































































@import "@/styles/basics/globals.scss";
.notification-container{
  border: 1px solid rgba(15, 15, 26, 0.2);
  border-radius: 10px;
  margin: 10px 10px 10px 10px;
  padding: 10px;
}
.circle-date-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.date-container{
  display: flex;
  flex-direction: row;
  justify-content: end;
}
.go-to-container{
  display: flex;
  flex-direction: row;
  justify-content: end;
}
.readed-notification{
  background: #e0e1e5;
}
.not-readed-notification{
  background: white;
}
.header-notifications{
  background: var(--color-primary-secondary);
  height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.title-header{
  color: white;
  margin-top: 15px;
}
.close-component{
  color: white;
  margin-top: 15px;
  margin-right: 5px;
}
.body-container{
  padding: 7px 30px 15px 30px;
}
.title-container{
  padding: 5px 30px 0 30px;
}
.custom-typo-body-2{
  color: rgba(0, 0, 16, 0.6);
  line-height: 17px;
  font-size: 14px;
  font-family: 'Open Sans', 'sans-serif';
  letter-spacing: 0.25px;
}

