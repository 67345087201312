

























































































































































































































































































































































@use "@material/layout-grid/mdc-layout-grid";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap');

:root {
  // Base Palette
  --primary: #00ADD9;
  --secondary: #0E5F85;
  --success: #57A869;
  --error: #DF2F4F;
  --warning: #E39B0F;
}

.light-theme {
  //PRIMARY
  --color-accent-primary: #00ADD9FF;
  --color-accent-secondary: #0E5F85FF;
  --color-accent-900: #1C5169FF;
  --color-accent-800: #0E5F85FF;
  --color-accent-700: #1F7AA0FF;
  --color-accent-600: #058FBAFF;
  --color-accent-500: #039CCDFF;
  --color-accent-400: #00ADD9FF;
  --color-accent-300: #44BBDDFF;
  --color-accent-200: #79CEE6FF;
  --color-accent-100: #AEE2EFFF;
  --color-accent-050: #EDF8FBFF;

  //PRIMARY
  --color-primary-primary: #0F0F1AFF;
  --color-primary-secondary: #20202BFF;
  --color-primary-900: #000010FF;
  --color-primary-800: #0F0F1AFF;
  --color-primary-700: #20202BFF;
  --color-primary-600: #343948FF;
  --color-primary-500: #494C5AFF;
  --color-primary-400: #606577FF;
  --color-primary-300: #808598FF;
  --color-primary-200: #BFC2CDFF;
  --color-primary-100: #DFE2EBFF;
  --color-primary-050: #F3F4F7FF;

  //BACKGROUND
  --color-background-background: #FFFFFFFF;
  --color-background-surface: #F3F4F7FF;
  --color-background-active-surface: #44BBDD27;
  --color-background-background-casillas: #FBFBFBFF;
  --color-background-background-inactive: #E0E1E5FF;
  --color-background-outline: #0F0F1A34;

  //TYPOGRAPHY
  --color-typography-on-accent: #FFFFFFFF;
  --color-typography-on-primary: #FFFFFFFF;
  --color-typography-on-secondary: #FFFFFFFF;
  --color-typography-on-background: #0F0F1AFF;
  --color-typography-on-surface: #0F0F1AFF;
  --color-typography-on-inactive: #626777FF;
  --color-typography-on-link: #1F7AA0FF;
  --color-typography-on-text: #0F0F1AFF;

  //OTHER
  --color-other-correcto: #57A869FF;
  --color-other-error: #DF2F4FFF;
  --color-other-advertencia: #E39B0FFF;

  //CUSTOM
  --color-custom-border: rgba(0, 0, 0, 0.12);
  --color-custom-stockchart-background: #FFFFFF;
  --color-custom-only-dark-theme-border: rgba(255, 255, 255, 0);
  --color-custom-only-light-theme-border: rgba(0, 0, 0, 0.12);
  --colo-custom-stockchart-ai-height: 77px;
  --color-custom-select-wallet-outline: rgba(0, 0, 0, 0.1);
  --color-custom-select-wallet-text: rgba(0, 0, 0, 0.6);
  --color-custom-table-titles: #f3f4f7;
  --color-custom-table-default: #ffffff;
  --color-custom-table-grey: #f8f8f8;
  --color-custom-table-hover: #CAF4FF;
  --color-custom-table-selected: #caf4ff;
  --color-custom-typo-stocklist: rgba(0,0,0,.38);
  --color-custom-active-parent-menu: #EDF8FB;
  --color-custom-search-bar: #000010;
  --color-custom-ticker-name-mobile: #ffffff;
  --color-custom-ticker-name-mobile-color: #000000;
  --color-custom-plat-mobile: #FFFFFF;
  --color-custom-typo-plat-mobile: #000000;
  --custom-color-stockchart-mobile-buttons: #F3F4F7;
  --color-custom-typo-mobile-buttons-stockchart: #000000;
  --color-custom-border-mobile-buttons-stockchart: rgba(15, 15, 26, 0.2);
  --color-custom-filters-bg: #f3f4f7;
  --color-custom-filter-inputs-font-color: rgba(0, 0, 0, 0.6);
  --custom-color-background-color-stockchart: #ffffff;
  --custom-color-stockchart-preferences-bg: #fbfbfb;
  --custom-color-stockchart-preferences-title: #0f0f1a;
  --custom-color-stockchart-preferences-text: #ffffff;
  --custom-color-stockchart-preferences-inside: #000010;
  --custom-color-add-tools: #FBFBFBFF;
  --custom-color-right-menu-bg: #ffffff;
  --custom-color-right-menu-font: #000010;
  --custom-color-right-menu-highlight: #f3f4f7;
  --custom-color-outline-thin: rgba(0, 0, 0, 0.1);
  --custom-color-outline-thick: rgba(0, 0, 0, 0.6);
  --menu-border-size: 0;

  //PALETA SIMPLIFICADA
  // PRIMARY
  --color-primary: #0F0F1A;
  --color-secondary: #20202B;
  --color-highlight: var(--primary);
  --color-muted: #BFC2CD;
  
  // BACKGROUND
  --color-background: #FFFFFF;
  --color-surface: #F3F4F7;
  --color-active-surface: #d9ecf3;
  --color-outline: rgba(15, 15, 26, 0.2);

  // TYPOGRAPHY
  --color-text-primary: #0F0F1A;
  --color-text-secondary: #606577;
  --color-text-inactive: rgba(0, 0, 0, 0.6);
  --color-text-link: var(--primary);

  // STATE
  --color-success: var(--success);
  --color-error: var(--error);
  --color-warning: var(--warning);
}

.dark-theme {
  //PRIMARY
  --color-accent-primary: #00ADD9FF;
  --color-accent-secondary: #0E5F85FF;
  --color-accent-900: #1C5169FF;
  --color-accent-800: #0E5F85FF;
  --color-accent-700: #1F7AA0FF;
  --color-accent-600: #058FBAFF;
  --color-accent-500: #039CCDFF;
  --color-accent-400: #00ADD9FF;
  --color-accent-300: #44BBDDFF;
  --color-accent-200: #79CEE6FF;
  --color-accent-100: #AEE2EFFF;
  --color-accent-050: #EDF8FBFF;

  //PRIMARY
  --color-primary-primary: #0F0F1AFF;
  --color-primary-secondary: #20202BFF;
  --color-primary-900: #000010FF;
  --color-primary-800: #0F0F1AFF;
  --color-primary-700: #20202BFF;
  --color-primary-600: #343948FF;
  --color-primary-500: #494C5AFF;
  --color-primary-400: #606577FF;
  --color-primary-300: #808598FF;
  --color-primary-200: #BFC2CDFF;
  --color-primary-100: #DFE2EBFF;
  --color-primary-050: #F3F4F7FF;

  //BACKGROUND
  --color-background-background: #0F0F1AFF;
  --color-background-surface: #000010FF;
  --color-background-active-surface: #44BBDD34;
  --color-background-background-casillas: #FBFBFBFF;
  --color-background-background-inactive: #E0E1E5FF;
  --color-background-outline: #FFFFFF27; //Seguro?

  //TYPOGRAPHY
  --color-typography-on-accent: #FFFFFFFF;
  --color-typography-on-primary: #FFFFFFFF;
  --color-typography-on-secondary: #FFFFFFFF;
  --color-typography-on-background: #FFFFFFFF;
  --color-typography-on-surface: #FFFFFFFF;
  --color-typography-on-inactive: #626777FF;
  --color-typography-on-link: #1F7AA0FF;
  --color-typography-on-text: #0F0F1AFF;

  //OTHER
  --color-other-correcto: #57A869FF;
  --color-other-error: #DF2F4FFF;
  --color-other-advertencia: #E39B0FFF;

  //CUSTOM
  --color-custom-border: rgba(255, 255, 255, 0.5);
  --color-custom-stockchart-background: #181818;
  --color-custom-only-dark-theme-border: rgba(255, 255, 255, 0.6);
  --color-custom-only-light-theme-border: rgba(255, 255, 255, 0.6);
  --colo-custom-stockchart-ai-height: 78px;
  --color-custom-select-wallet-outline: rgba(255, 255, 255, 1);
  --color-custom-select-wallet-text: rgba(255, 255, 255, 1);
  --color-custom-table-titles: #000010;
  --color-custom-table-default: #20202B;
  --color-custom-table-grey: #343948;
  --color-custom-table-hover: #494C5A;
  --color-custom-table-selected: #1C5169;
  --color-custom-typo-stocklist: #FFFFFF;
  --color-custom-active-parent-menu: #1c5169;
  --color-custom-search-bar: #606577;
  --color-custom-ticker-name-mobile: #343948;
  --color-custom-ticker-name-mobile-color: #ffffff;
  --color-custom-plat-mobile: #606577;
  --color-custom-typo-plat-mobile: #ffffff;
  --custom-color-stockchart-mobile-buttons: #000010;
  --color-custom-typo-mobile-buttons-stockchart: #ffffff;
  --color-custom-border-mobile-buttons-stockchart: rgba(255, 255, 255);
  --color-custom-filters-bg: #20202b;
  --color-custom-filter-inputs-font-color: rgba(255, 255, 255, 0.8);
  --custom-color-background-color-stockchart: #000010;
  --custom-color-stockchart-preferences-bg: #343948;
  --custom-color-stockchart-preferences-title: #0f0f1a;
  --custom-color-stockchart-preferences-text: #ffffff;
  --custom-color-stockchart-preferences-inside: #ffffff;
  --custom-color-add-tools: #343948;
  --custom-color-right-menu-bg: #343948;
  --custom-color-right-menu-font: #ffffff;
  --custom-color-right-menu-highlight: #20202b;
  --custom-color-outline-thin: #ffffff;
  --custom-color-outline-thick: #ffffff;
  --menu-border-size: 1px;

  //PALETA SIMPLIFICADA
  // PRIMARY
  --color-primary: #FFFFFF;
  --color-secondary: #606577;
  --color-highlight: var(--primary);
  --color-muted: #343948;

  // BACKGROUND
  --color-background: #0F0F1A;
  --color-surface: #20202B;
  --color-active-surface: #193141;
  --color-outline: rgba(255, 255, 255, 0.3);

  // TYPOGRAPHY
  --color-text-primary: #FFFFFF;
  --color-text-secondary: #BFC2CD;
  --color-text-inactive: rgba(255, 255, 255, 0.6);
  --color-text-link: var(--primary);

  // STATE
  --color-success: var(--success);
  --color-error: var(--error);
  --color-warning: var(--warning);
}

/*Eurekers typographies*/
.typo-headline-6 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.18px;
}

.typo-body-2 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

.typo-subtitle-2 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.1px;
}

.typo-caption {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
}

.typo-headline-4{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 36px;
  /* identical to box height, or 106% */
}

.typo-capsheading-6 {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: left;
}

.typo-button {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

html, body {
  height: 100% !important;
}

.noscroll, .noscroll #widgets {
  overflow: hidden;
}

:root {
  --mdc-layout-grid-gutter-desktop: 24px;
  --mdc-layout-grid-margin-desktop: 10px;
  --mdc-layout-grid-margin-phone: 0px;
  --mdc-theme-primary: #00add8;
  --mdc-theme-secondary: #00303D;
  --mdc-theme-surface: rgb(221, 221, 221);
  --mdc-theme-background: yellow;
  --mdc-theme-error: red;
  --mdc-theme-on-primary: rgb(255, 255, 255);
  --mdc-theme-on-secondary: blue;
  --mdc-theme-on-surface: black;
  --mdc-theme-on-error: whitesmoke;
}

.content-main-container {
  overflow: auto;
}

.mdc-layout-grid {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px;  
}
.margin-layout {
  margin-left:20px;
}
.margin-sidebar-main-menu-desktop {
  margin-left: 53px;
}
@media(max-width: 400px) {
  .margin-layout{
    //margin-top: 40px !important;;
    margin-left: 0px !important;;
    width: 100% !important;
    padding:0px !important;
  }
}

@media(max-height: 400px) {
  .margin-layout{
    //margin-top: 40px !important;;
    margin-left: 0px !important;
    width: 100% !important;
    padding: 0px !important;
  }
}

  #app-overlay-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #app-overlay-background {
    background-color: black;
    position: fixed;
    height: 100%;
    width: 100%;
    opacity: 0.3;
  }
  #app-overlay-iframe-container {
    position: relative;
    z-index: 101;
    width: 900px;
    height: 600px;
    text-align: center;
    background-color: white;
  }

  #app-overlay-iframe-container-mobile {
    position: relative;
    z-index: 101;
    width: 390px;
    height: 390px;
    text-align: center;
    background-color: white;
  }
  #overlay-iframe {
    width: 100px;
    height: 100px;
  }

  .app-iframe-video-mobile{
    margin-top: -20px;
  }

  .app-close-iframe :hover{
    cursor: pointer;
  }

  .nopadding {
    padding: 0;
  }

  #fullscreen-button-container-all-pages:hover {
  cursor: pointer;
  height: 40px;
  width: 70px;
  padding: 20px;
  }

  #fullscreen-button-container-all-pages span:hover{
    font-size: 35px;
  }

  #fullscreen-button-container-all-pages span {
    font-size: 30px;
    font-size: 25px;
    transition: 0.5s;
  }

  #fullscreen-button-container-all-pages {
    box-shadow: 1px 1px 5px black;
    background-color: #f3f4f7;
    order: 1;
    z-index: 1;
    position: fixed;
    width: 40px;
    height: 30px;
    padding-top: 5px;
    text-align: center;
    right: 0;
    top: 0;
    transition: 0.5s;
  }
  .banner-class{
    margin-left: 7%;
    width: 90%;
    height: 50px;
    position: absolute;
    top: 5px;
    background-color: #FFD481;
    z-index: 9999999;
    border-radius: 50px;
  }
  .banner-class-mobile{
    margin-left: 7%;
    width: 90%;
    height: 50px;
    position: absolute;
    top: 5px;
    background-color: #FFD481;
    z-index: 9999999;
    border-radius: 50px;
  }

  .notifications-fullscreen{
    background: rgba(0, 0, 0, 0.15);
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: fixed;
    z-index: 15;
  }

@import "@/styles/basics/globals.scss";
@import './../node_modules/vue-on-toast/dist/vue-on-toast.css';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;1,300;1,400&display=swap');
@import url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Sharp|Material+Icons+Round|Material+Icons+Two+Tone");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");
