








































































































































@import "@/styles/basics/globals.scss";

#notifications {
  background-color: var(--color-background);
}

.notification-container {
  border: 1px solid var(--color-outline);
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  background-color: var(--color-background);
}

.circle-date-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.date-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.go-to-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.readed-notification {
  background: var(--color-muted);
  border-left: 4px solid var(--color-muted);
}

.not-readed-notification {
  background: var(--color-background);
  border-left: 4px solid var(--color-error);
}

.header-notifications {
  background: var(--color-background);
  height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  border-bottom: 1px solid var(--color-outline);
}

.title-header {
  color: var(--color-text-primary);
  margin-top: 0;
  font-family: 'Open Sans', sans-serif;
}

.close-component {
  color: var(--color-text-secondary);
  margin-top: 0;
  margin-right: 5px;
  cursor: pointer;
}

.close-component:hover {
  color: var(--color-text-primary);
}

.body-container {
  padding: 7px 30px 15px 30px;
}

.notifications-title-container {
  padding: 5px 30px 0 30px;
}

.custom-typo-body-2 {
  color: var(--color-text-secondary);
  line-height: 17px;
  font-size: 14px;
  font-family: 'Open Sans', 'sans-serif';
  letter-spacing: 0.25px;
}

.body-title {
  color: var(--color-text-secondary) !important;
}

.body-text {
  color: var(--color-text-secondary);
}

.body-text ::v-deep a {
  color: var(--color-highlight);
  text-decoration: underline;
}

/* Scrollbar styling */
#notifications > div {
  overflow-y: auto;
  max-height: 94%;
}

#notifications > div::-webkit-scrollbar {
  width: 8px;
}

#notifications > div::-webkit-scrollbar-thumb {
  background-color: var(--color-outline);
  border-radius: 4px;
}

#notifications > div::-webkit-scrollbar-track {
  background: transparent;
}
