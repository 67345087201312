










































































































#overlay-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
#overlay-background {
  background-color: black;
  position: fixed;
  height: 100%;
  width: 100%;
  opacity: 0.3;
}
#overlay-image-container {
  position: relative;
  z-index: 101;
}
#overlay-image {
  width: 100px;
  height: 100px;
}
