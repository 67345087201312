



































































































































































































































































































































































































































































































//modifiable attributes
$selector_height: 48px;
$login_selector_focus_padding: 15px;
$login_selector_not_focus_padding: 7.5px;
$login_selector_focus_font_size: 18px;
$login_selector_focus_icon_size: 28px;
$closed_width: 64px; //Deberian ser 72 pero si no igual no esta para la master porque descuadra todo
$width: 75%;
$border_color: #868993;
$profile_pic_size: 40px;
$profile_padding: 15px;

:root {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.noscroll {
  overflow: hidden;
}

#menu-hider-mobile {
  background-color: rgba(0,0,0,0.5);
  z-index: 99999999999997;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
}

#menu-container-mobile {
  width: $width;
  background-color: var(--color-primary-primary);
  color: var(--color-typography-on-primary);
  font-weight: bold;
  position: fixed;
  z-index: 99999999999999;
  top: 0px;
  cursor: pointer;
  height: 100% !important;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
}

#menu-container-frame-mobile {
  z-index: 99999999999998;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

#login-selector-mobile {
  padding: $login_selector_not_focus_padding 0;
  transition: padding, 0.2s;
  transition: font-size, 0.2s;
}

#login-selector-mobile:hover {
  padding: $login_selector_focus_padding 0px;
  font-size: $login_selector_focus_font_size;
}

#login-selector-mobile:hover .md-24 {
  transition: font-size, 0.2s;
}

#login-selector-mobile:hover .md-24 {
  font-size: $login_selector_focus_icon_size !important;
}

#profile-container-mobile {
  order: 1;
  border-bottom: 1px solid $border_color;
}

#link-container-mobile {
  order: 2;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

#bottom-pinned-container-mobile {
  order: 3;
  margin-top: auto;
  border-top: 1px solid $border_color;
}

.nav-selector-container-mobile {
  height: $selector_height;
}

.nav-link-icon-container-mobile, .nav-link-dropdown-icon-container-mobile, .profile-pic-container-mobile {
  width: $closed_width;
  text-align: center;
  display: inline-block;
  vertical-align: top;
}

.nav-link-icon-mobile {
  line-height: $selector_height !important;
  text-align: center;
}

.nav-link-text-container-mobile, .profile-text-container-mobile {
  display: inline-block;
  vertical-align: top;
}

.nav-link-text-mobile {
  line-height: $selector_height !important;
  text-align: center;
}

.nav-link-icon-container-mobile .material-icons-outlined, .nav-link-dropdown-icon-container-mobile .material-icons-outlined {
  margin: 0px !important;
}

.selected, .selected:hover {
  background-color: var(--color-accent-primary);
}

.selected-dropdown .nav-link-dropdown-icon-container-mobile {
  transform: rotate(-180deg);
}

.nav-link-dropdown-icon-container-mobile {
  transition: all .25s ease-out;
  float: right;
}

.nav-dropdown-option {
  background-color: var(--color-primary-900);
  transition: background-color 0.2s;
}

.option-selected {
  background-color: var(--color-accent-primary);
}

.nav-dropdown-options-container, .profile-options-container {
  overflow: hidden;
  overflow-y: auto;
  transition: opacity 0.5s;
}

.dropdown-options-closed {
  height: 0px;
  opacity: 0%;
}

.profile-selector-container {
  padding: $profile_padding 0;
  height: $selector_height;
}

.profile-title-text {
  display: block;
}

.profile-pic-container-mobile {
  height: 100%;
}

.important-highlight {
  background-color: orange;
}

#profile-pic-center-helper {
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

.log-out-text {
  color: var(--color-other-error);
}

#log-out-button-container {
  transition: background-color, 0.2s;
}

#log-out-button-container:active {
  background-color: var(--color-accent-primary);
}

#log-out-button-container:active .log-out-text {
  color: white !important;
}

.profile-pic {
  display: inline-block;
  vertical-align: middle;
  height: $profile_pic_size;
}

//Slider transition

.slider-enter-active,
.slider-leave-active {
  transition: all .15s !important;
}

.slider-enter,
.slider-leave-to {
  transform: translateX(-100vw) !important;
}

//Fader transition

.fader-enter-active,
.fader-leave-active {
  transition: opacity .15s !important;
}

.fader-enter,
.fader-leave-to {
  opacity: 0 !important;
}

#upper-menu-container-mobile {
  background-color: var(--color-primary-primary);
  height: 64px;
  top: 0;
  width: 100%;
  transition: top 0.3s;
  position: fixed;
  z-index: 3;
}

#upper-menu-container-layout-changer-mobile {
  background-color: transparent;
  height: 48px;
  width: 100%;
}

#upper-menu-icon-container {
  height: 64px;
  width: 64px;
  vertical-align: middle;
  text-align: center;
}

#upper-menu-icon {
  line-height: 64px !important;
  color: white;
  font-size: 30px;
}

