
















































.banner-container{
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-banner-mobile{
  font-size: 10px;
  width: 100%;
  margin-left: 5px;
}
