







































































@use "@material/card";
@use '@material/button/mdc-button';
@use "@material/switch/mdc-switch";
.mdc-card {
  height: 350px;
  width: 350px;
}
@include card.core-styles;
