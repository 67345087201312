


































































































































































































































































































































































































































































































































































//modifiable attributes
$selector_height: 48px;
$login_selector_focus_padding: 15px;
$login_selector_not_focus_padding: 7.5px;
$login_selector_focus_font_size: 18px;
$login_selector_focus_icon_size: 28px;
$closed_width: 64px; //Deberian ser 72 pero si no igual no esta para la master porque descuadra todo
$open_width: 315px;
$border_color: #868993;
$profile_pic_size: 27px;
$profile_padding: 15px;

:root {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

#menu-container {
  background-color: var(--color-primary-primary);
  color: var(--color-typography-on-primary);
  font-weight: bold;
  position: fixed;
  z-index: 999;
  top: 0px;
  cursor: pointer;
  height: 100vh;
  transition: width 0.15s ease-in;
  overflow: hidden;
  border-right: var(--menu-border-size) solid #868993;
}

#menu-container-frame {
  width: $open_width;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

#login-selector {
  padding: $login_selector_not_focus_padding 0;
  transition: padding, 0.2s;
  transition: font-size, 0.2s;
}

#login-selector:hover {
  padding: $login_selector_focus_padding 0px;
  font-size: $login_selector_focus_font_size;
}

.md-24 {
  font-size: 20px !important;
}

#login-selector:hover .md-24 {
  transition: font-size, 0.2s;
}

#login-selector:hover .md-24 {
  font-size: $login_selector_focus_icon_size !important;
}

#profile-container {
  order: 1;
  border-bottom: 1px solid $border_color;
}

#link-container {
  order: 2;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

#bottom-pinned-container {
  order: 3;
  margin-top: auto;
  border-top: 1px solid $border_color;
}

.menu-closed {
  width: $closed_width;
}

.menu-open {
  width: $open_width;
}

.nav-selector-container {
  height: $selector_height;
}

.profile-selector-container:hover {
  background-color: var(--color-primary-secondary);
  transition: background-color, 0.2s;
}

.nav-selector-container:hover {
  background-color: var(--color-primary-secondary);
  transition: background-color, 0.2s;
}
.nav-selector-container.important-highlight:hover {
  background-color: #00add8;
  transition: background-color, 0.2s;
}
.important-highlight {
  background-color: orange;
}

.nav-link-icon-container, .nav-link-dropdown-icon-container, .profile-pic-container{
  width: $closed_width;
  text-align: center;
  display: inline-block;
  vertical-align: top;
}

.nav-link-icon {
  line-height: $selector_height !important;
  text-align: center;
}

.nav-link-text-container, .profile-text-container {
  display: inline-block;
  vertical-align: top;
}
.profile-text-container {
  margin-top: 6px;
}
.nav-link-text {
  font-size: 14px;
  line-height: $selector_height !important;
  text-align: center;
}

#notification-container {
  border-top: 1px solid $border_color;
}

.nav-link-icon-container .material-icons-outlined, .nav-link-dropdown-icon-container .material-icons-outlined {
  margin: 0px !important;
}

.selected, .selected:hover {
  background-color: var(--color-accent-primary);
}

.child-selected {
  background-color: var(--color-primary-secondary);
}

.selected-dropdown .nav-link-dropdown-icon-container {
  transform: rotate(-180deg);
}

.nav-link-dropdown-icon-container {
  transition: all .25s ease-out;
  float: right;
}

.nav-dropdown-option {
  background-color: var(--color-primary-900);
  transition: background-color 0.2s;
}

.nav-dropdown-option:hover {
  background-color: var(--color-primary-secondary);
}

.option-selected, .option-selected:hover {
  background-color: var(--color-accent-primary);
}

.nav-dropdown-options-container, .profile-options-container {
  overflow: hidden;
  overflow-y: auto;
  transition: opacity 0.5s;
}

.dropdown-options-closed {
  height: 0px;
  opacity: 0%;
}

.profile-selector-container {
  padding: $profile_padding 0;
  height: $selector_height;
}

.profile-title-text {
  display: block;
}

.profile-pic-container {
  height: 100%;
}

#profile-pic-center-helper {
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

.log-out-text {
  color: var(--color-other-error);
}

#log-out-button-container {
  transition: background-color, 0.2s;
}

#log-out-button-container:active {
  background-color: var(--color-accent-primary);
}

#log-out-button-container:active .log-out-text {
  color: var(--color-typography-on-primary) !important;
}

.profile-pic {
  display: inline-block;
  vertical-align: middle;
  //height: $profile_pic_size;
  font-size: $profile_pic_size !important;
}

a {
  text-decoration: unset;
  color: unset;
}
.circle {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 9px;
}
